import React from "react";
import styled, { keyframes } from "styled-components";
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { fadeInLeftBig } from "react-animations";
import NewAboutServices from "../components/NewAboutServices";
import NewAboutIntro from "../components/NewAboutIntro";
import NewAboutTranslations from "../components/NewAboutTranslations";

const fadeInAnimationTitle = keyframes`${fadeInLeftBig}`;

export const Title = styled.h1`      
    color: darkcyan;  
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 28px;
    animation: 0.5s ${fadeInAnimationTitle}
`;

export const SubTitle = styled.h2`      
    color: black; 
    display: flex;
    justify-content: center;
    font-size: 1.20rem;
`;

export const FormBox = styled.form`
    padding-top: 1.25rem;
`;

function NewAbout() {
    return (
        <section id="page-wrap">
            <Grid>
                <Row center="xs">
                    <Col xs={12}>
                        <FormBox>
                            <Title>About us</Title>
                        </FormBox>
                    </Col>
                    <Col xs={12}>
                        {/*<br/>*/}
                        <SubTitle>Learn more about our brand.</SubTitle>
                    </Col>
                </Row>
            </Grid>
            <NewAboutIntro />
            <NewAboutServices />
            <NewAboutTranslations />
            <br />
        </section>
    );
}

export default NewAbout;
