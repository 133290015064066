import React from "react";
import { useSpring, animated } from "react-spring";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';


export const Icon = styled(FontAwesomeIcon)`
  color: #0000CD;
  font-size: 1.3rem;
  margin: 0 .5rem;
  :hover{
    color: #abcccf;
    transition: color .3s linear;
    transform: scale(1.2);
  }
`;

function CardInfo(props) {
  const style = useSpring({
    opacity: 1,
    from: { opacity: 0 }
  });
  return (
    <animated.div className="z-card-info" style={style}>
      <p className="z-card-title">{props.title}</p>
      <p className="z-card-sub-title">{props.subTitle}</p>
      <a className="view-text" href={props.link} target="a_blank" rel="noopener noreferrer">
        View<Icon icon={faEye} />
      </a>
    </animated.div>
  );
}

export default CardInfo;
