import React from "react";

import Form from "react-bootstrap/Form";
import Axios from "axios";
import styled, { keyframes } from 'styled-components';
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { fadeInLeftBig, bounceIn } from "react-animations";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fadeInAnimationTitle = keyframes`${fadeInLeftBig}`;
const fadeInContactForm = keyframes`${bounceIn}`;

export const AnimatedButton = styled.button`   
  margin-top: 1rem;   
  color: #fff;  
  font-size: 1.1rem;
  line-height: 1.2;
  display: ${props => props.disabled ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 39px;
  width: 6em;
  border-radius: 12px;
  background: black;
  outline: none;
  border: none;
  font-weight: 500;
  position: relative;
  z-index: 1;
  transition: all .4s;
  cursor: pointer;

  &:hover{
    transform: scale(1.2);
  }
`;

export const SentDisabled = styled.button`   
  margin-top: 1rem;   
  color: #fff;  
  font-size: 1.1rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 39px;
  width: 6em;
  border-radius: 12px;
  background: darkcyan;
  outline: none;
  border: none;
  font-weight: 700;
  position: relative;
  z-index: 1;
  transition: all .4s;
  cursor: pointer;

`;

export const Title = styled.h1`      
    color: darkcyan;  
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 28px;
    animation: 0.5s ${fadeInAnimationTitle}
`;

export const SubTitle = styled.h2`      
    color: black; 
    display: flex;
    justify-content: center;
    font-size: 1.20rem;
`;

export const FormBox = styled.form`
  padding-top: 1.25rem;
`;

export const FormBoxContainer = styled.div`   
    border-radius: 1rem;
    border: 4px solid darkcyan;
    box-shadow:  0 22px 70px 4px rgba(215,211,203,0.56), 0 0 0 1px rgba(215,211,203, 0.3);
    background-color: white;
    padding: 1.5rem;
    animation: 1.5s ${fadeInContactForm}
`;

// export const Paragraph = styled.h3`
//     color: black;
//     display: flex;
//     font-size: 1.45rem;
//     font-weight: 300;
//     justify-content: center;
// `;

class NewContact extends React.Component {

    notifyA = () => toast.success("Message sent! We'll be in touch soon 👍");
    notifyB = () => toast.error("Oops! 😲 Please fill in all the required fields");

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null,
        }
    }


    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value; //if type is checkbox then set to "checked", otherwise set to "value"
        const name = target.name;

        this.setState({
            [name]: value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault(); //prevents the refresh on page

        this.setState({
            disabled: true //disable form to be submitted twice
        });
        //external call
        Axios.post('https://us-central1-reactapp-ttl20-api.cloudfunctions.net/api/email', this.state)
            .then(res => {
                if (res.data) {
                    this.setState({
                        disabled: true,
                        emailSent: true  //email successfully sent
                    });
                    this.notifyA()
                } else {
                    this.setState({
                        disabled: false,
                        emailSent: false  //email unsuccessful, allow user to send a new one
                    });
                    this.notifyB()
                }
            })
            .catch(err => {
                this.setState({
                    disabled: false,  //error, so we allow the user to send a new email
                    emailSent: false,
                });
                this.notifyB()
            })
    };

    render() {
        return (
            <section id="page-wrap">
                <Grid>
                    <Row center="xs">
                        <Col xs={12} md={8} lg={8}>
                            <FormBox onSubmit={this.handleSubmit}>
                                <Title>Contact</Title>
                                <SubTitle>Need help with a cool project? Get in touch today :)</SubTitle>

                                <FormBoxContainer>
                                    <Form.Group>
                                        <Form.Label htmlFor="full-name">Full name:</Form.Label>
                                        <Form.Control id="full-name" placeholder="Enter your full name..." name="name" type="text" value={this.state.name} onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="email">Email:</Form.Label>
                                        <Form.Control id="email" placeholder="Enter your email address..." name="email" type="email" value={this.state.email} onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="message">Message:</Form.Label>
                                        <Form.Control id="message" placeholder="Got something in mind? Enter your message here..." name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange} />
                                    </Form.Group>
                                </FormBoxContainer>
                                <br></br>

                                {/*<AnimatedButton disabled={this.state.disabled}>Send</AnimatedButton>*/}
                                {!this.state.disabled ? <AnimatedButton disabled={this.state.disabled}>Send</AnimatedButton>
                                    : <SentDisabled disabled={this.state.disabled}>Sent!</SentDisabled>}
                                {/*{this.state.emailSent === true && <p className="d-inline z-success-msg">Email Sent</p>}*/}
                                {/*{this.state.emailSent === false && <p className="d-inline z-err-msg">Email Not Sent!</p>}*/}
                                <br></br>
                            </FormBox>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

export default NewContact;
