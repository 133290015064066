import React from "react";

import SharedNav from "./shared/SharedNav";
import NewFooter from "./components/NewFooter";

import {toast, ToastPosition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Call it once in your app. At the root of your app is the best place
toast.configure({
    position: ToastPosition.TOP_CENTER,
    autoClose: 2800,
});

function App() {
    return (
        <main>
            <SharedNav/>
            <NewFooter/>
        </main>
    );
}

export default App;
