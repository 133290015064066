import React, { useState } from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { fadeInLeftBig } from "react-animations";
import FAQ from "../components/FAQ";

import styled, { keyframes } from 'styled-components';

const fadeInAnimationTitle = keyframes`${fadeInLeftBig}`;


export const Title = styled.h1`      
    color: darkcyan;  
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 28px;
    animation: 0.5s ${fadeInAnimationTitle}
`;

export const SubTitle = styled.h2`      
    color: black; 
    display: flex;
    justify-content: center;
    font-size: 1.20rem;
`;

export const Paragraph = styled.h3`      
    color: black; 
    display: flex;
    font-size: 0.95rem;
    font-weight: 300;
    justify-content: center;
`;

export const FormBox = styled.form`
    padding-top: 1.25rem;
`;

export const CardBoxContainer = styled.div`   
    border-radius: 1rem;
    border: 4px solid #B0C4DE;
    box-shadow:  0 22px 70px 4px rgba(215,211,203,0.56), 0 0 0 1px rgba(215,211,203, 0.3);
    background-color: #003333;
    padding: 2rem;
    animation: 1.5s ${fadeInAnimationTitle}
`;


function NewFAQs() {

    const [faqs, setfaqs] = useState([
        {
            question: 'What forms of payment can I use?',
            answer: 'All debit/credit cards are accepted including PayPal.',
            open: false
        },
        {
            question: 'When can I pay?',
            answer: 'Development work will not commence until a minimum deposit of 50% of the agreed costs is paid before the start of a project. The remaining 50% is due upon completion.',
            open: false
        },
        {
            question: 'How long will it take to complete my project?',
            answer: 'This depends on the size of the project and it varies for each client. Please use the contact form to receive a free consultation.',
            open: false
        }
    ]);

    //index relates to one of the FAQs above e.g. first one index is 0
    const toggleFAQ = index => {
        setfaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }
            return faq;
        }))
    }



    return (
        <section>
            <Grid>
                <Row>
                <Col xs={12}>
                        <FormBox>
                            <Title>FAQs</Title>
                        </FormBox>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <SubTitle>Frequently asked questions.
                        </SubTitle>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <Paragraph>Click on each card to find out more</Paragraph>
                    </Col>


                    <div className="faqs">
                    <Col xs={12} md={12} lg={12}>
                        {faqs.map((faq, i) => (
                            <FAQ faq={faq} index={i} toggleFAQ = {toggleFAQ} />
                        ))}
    
                    </Col>
                    </div>
                    
                </Row>
                <br></br>

            </Grid>
        </section>
    );
}

export default NewFAQs;