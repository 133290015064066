import React from "react";

import dolcimix from "../assets/images/dolcimix.svg";
import sflex from "../assets/images/sflex.svg";
import coming from "../assets/images/comingsoon.svg";
import portfolio from "../assets/images/portfolio.jpeg";


import Card from "../components/Card";

import {Grid, Row} from 'react-styled-flexboxgrid';


class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        // {
        //   id: 0,
        //   title: "Dice App",
        //   subTitle: "Interactive dice game built with Vanilla JS",
        //   img: dicegame,
        //   link: "https://github.com/zay-asad/dice-game",
        //   selected: false
        // },
        {
          id: 0,
          title: "Personal Website",
          subTitle: "My personal website",
          img: portfolio,
          link: "https://www.zayarts.co.uk",
          selected: false
        },
        {
          id: 1,
          title: "DolciMix",
          subTitle: "Custom Logo Design for a bakery business",
          img: dolcimix,
          link: "https://www.instagram.com/dolci.mix/",
          selected: false
        },
        {
          id: 2,
          title: "Sonia's Flex",
          subTitle: "Custom Logo Design for a beauty business",
          img: sflex,
          link: "https://www.instagram.com/soniasflex/",
          selected: false
        },
        {
          id: 3,
          title: "X",
          subTitle: "Coming Soon",
          img: coming,
          link: "https://traptechlabs.com/212121",
          selected: false
        }
      ]
    };
  }

  handleCardClick = (id, card) => {
    // console.log(id);
    let items = [...this.state.items];
    items[id].selected = items[id].selected ? false : true;
    items.forEach(item => {
      if (item.id !== id) {
        item.selected = false;
      }
    });

    this.setState({
      items
    });
  };

  makeItems = items => {
    return items.map(item => {
      return (
        <Card
          item={item}
          click={(e => this.handleCardClick(item.id, e))}
          key={item.id}
        />
      );
    });
  };

  render() {
    return (
        <section id="page-wrap">
      <Grid>
        <Row>
            {this.makeItems(this.state.items)}
        </Row>
      </Grid>
        </section>
    );
  }
}

export default Carousel;
