import React from "react";

import styled, { keyframes } from 'styled-components';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import Carousel from "./Carousel";
import { fadeInLeftBig } from "react-animations";

const fadeInAnimationTitle = keyframes`${fadeInLeftBig}`;

export const Title = styled.h1`      
    color: darkcyan;  
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 28px;
    animation: 0.5s ${fadeInAnimationTitle}
`;

export const SubTitle = styled.h2`      
    color: black; 
    display: flex;
    justify-content: center;
    font-size: 1.20rem;
`;

export const Paragraph = styled.h3`      
    color: black; 
    display: flex;
    font-size: 0.95rem;
    font-weight: 300;
    justify-content: center;
`;

export const FormBox = styled.form`
    padding-top: 1.25rem;
`;

function NewProjects() {
    return (
        <section id="page-wrap">
            <Grid>
                <Row>
                    <Col xs={12}>
                        <FormBox>
                            <Title>Projects</Title>
                        </FormBox>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <SubTitle>View our latest projects here.
                        </SubTitle>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <Paragraph>Click on each card to find out more</Paragraph>
                    </Col>
                    <Col xs={12}>
                        <Carousel />
                    </Col>
                </Row>
            </Grid>
        </section>

    );
}

export default NewProjects;
