import React from "react";
import styled from 'styled-components';
import NewHome from "../components/NewHome";
import NewAbout from "./NewAbout";
import NewProjects from "../components/NewProjects";
import NewContact from "../components/NewContact";
import NoMatchPage from "../components/NoMatchPage";
import NewFAQs from "../components/NewFAQs";


import { stack as Menu } from 'react-burger-menu'
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from "react-router-dom";

export const Section = styled(Link)`
  cursor: pointer;
  padding-bottom: .5rem;
  color: white;
  font-size: 2.2rem;
  text-decoration: none;
    &:hover {
    text-decoration: none;
    color: cyan;
    border: 3px ridge cyan;
    padding: 4px;
    border-radius: 20px;
    text-align: center;
}
`;

class SharedNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    //functionality to keep state in sync when user tries to open/or close the react burger menu
    //e.g. pressing the ESC key will close the menu
    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen })
    }

    // functionality to close the menu
    closeMenu() {
        this.setState({ menuOpen: false })
    }

    render() {
        return (
            <Router>
                <div id="outer-container">
                    <Menu
                        isOpen={this.state.menuOpen}
                        onStateChange={(state) => this.handleStateChange(state)}
                        pageWrapId={"page-wrap"}
                        outerContainerId={"outer-container"}
                        disableAutoFocus
                        right
                    >
                        <Section onClick={() => this.closeMenu()} to="/">Home</Section>
                        <Section onClick={() => this.closeMenu()} to="/about">About</Section>
                        <Section onClick={() => this.closeMenu()} to="/projects">Projects</Section>
                        <Section onClick={() => this.closeMenu()} to="/contact">Contact</Section>
                        <Section onClick={() => this.closeMenu()} to="/faq">FAQs</Section>

                    </Menu>
                </div>
                <Switch>
                    <Route path="/" exact component={NewHome} />
                    <Route path="/about" component={NewAbout} />
                    <Route path="/projects" component={NewProjects} />
                    <Route path="/contact" component={NewContact} />
                    <Route path="/faq" component={NewFAQs} />
                    <Route path="*" component={NoMatchPage} />
                </Switch>
            </Router>
        );
    }
}
export default SharedNav;