import React from 'react';
import SharedAboutCard from '../shared/SharedAboutCard';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

function NewAboutTranslations() {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12} lg={12}>
                        <SharedAboutCard
                            title="Translation services"
                            description="In addition, Traptech Labs can also provide product and website translations from English into Italian. Our interpreters can deliver high-quality authentic translations."
                        />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
}

export default NewAboutTranslations;