import React from 'react';
import styled, {keyframes} from 'styled-components';
import {Col, Row} from 'react-styled-flexboxgrid';
import { zoomIn } from "react-animations";
const fadeInAnimationTitle = keyframes`${zoomIn}`;


export const Title = styled.h2`      
    color: white;  
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
`;

export const CardBoxContainer = styled.div`   
    border-radius: 1rem;
    border: 4px solid #B0C4DE;
    box-shadow:  0 22px 70px 4px rgba(215,211,203,0.56), 0 0 0 1px rgba(215,211,203, 0.3);
    background-color: #003333;
    padding: 2rem;
    animation: 1.5s ${fadeInAnimationTitle}
`;

export const Description = styled.p`      
    color: white;  
    font-size: 1.1rem;
    text-align: center;
    
    @media (min-width: 1280px) {
      text-align: left;
    }
`;

const SharedAboutCard = ({ title, description }) => (
    <CardBoxContainer>
        <Row middle="md">
            <Col xs={12} md={3}>
                <Title>{title}</Title>
            </Col>
            <Col xs={12} md={9}>
                <Description>{description}</Description>
            </Col>
        </Row>
    </CardBoxContainer>
)

export default SharedAboutCard