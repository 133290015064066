import React from 'react';
import SharedAboutCard from '../shared/SharedAboutCard';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

function NewAboutServices() {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12} lg={12}>
                        <SharedAboutCard
                            title="Web services"
                            description="Website development, Content Management System(CMS), Logo design & branding, Hosting and monitoring, UX/UI optimization."
                        />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
}

export default NewAboutServices;