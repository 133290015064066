import React from 'react';
import SharedAboutCard from '../shared/SharedAboutCard';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

function NewAboutIntro() {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12} lg={12}>
                        <SharedAboutCard
                            title="What we do"
                            description="We offer bespoke web development services specifically designed to improve your online presence."
                        />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
}

export default NewAboutIntro;