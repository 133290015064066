import React from 'react';
import profile from "../assets/logo/vector/Artwork1.svg";
import styled, { keyframes } from "styled-components";
import {
    NavLink
} from "react-router-dom";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { rotateIn } from "react-animations";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const animatedNoMatchPage = keyframes`${rotateIn}`;

export const Icon = styled(FontAwesomeIcon)`
  color: #4db8ff;
  margin: 0 .5rem;
  margin-top: 5rem;
  font-size: 18px;
  :hover{
    animation: text-shadow-pop-bottom 0.6s both;
    color: darkcyan;
    transition: color .2s linear;
  }

  @keyframes text-shadow-pop-bottom {
    0% {
      text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555;
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
    }
  }
`;


export const NoMatch = styled.h1`      
    height: 80px;
    width: 80px;
    display: block;
    margin-top: 60px;
    background-image: url(${profile});
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
`;

export const Title = styled.h1`
    margin-top: 70px;
    color: white;
    display: flex;
    justify-content: center;
    font-weight: 775;
    text-shadow: 10px 10px 20px black, 0 0 25px black, 0 0 20px black;
    font-size: 4.5rem;
    border: 1.5px dotted white;
`;

export const SubTitle = styled.h3`
    margin-top: 0px;
    padding-top: 10px;
    font-size: 1.8rem;
    color: #F5FFFA;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 400;
`;

export const Paragraph = styled.h3`
    margin-top: 0px;
    padding-top: 10px;
    font-size: 1.1rem;
    color: #F5FFFA;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 400;
`;

export const LinkTitle = styled.h3`
    margin-top: -30x;
    padding-bottom: 10px;
    font-size: 16px;
    display: flex;
    color: white;
    justify-content: center;
    font-weight: 600;
`;

export const Wrapper = styled.div`
  background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(23,100,244,1) 69%, rgba(14,14,93,0.9878326330532213) 100%);
    animation: 1s ${animatedNoMatchPage}    
`;

function NoMatchPage() {
    return (
        <section id="page-wrap">
            <Grid>
                <Row center="xs">
                    <Col xs={12}>
                        <Wrapper>
                            <Title>404</Title>
                            <Col xs={12}>
                                <SubTitle>sorry, the page you were looking for doesn't exist :( </SubTitle>
                                <Paragraph>please make sure the page address is spelled correctly</Paragraph>
                            </Col>
                            <NoMatch />
                            <LinkTitle>
                                <NavLink className="no-match-link" to="/">Go to traptechlabs.com
                                    <Icon icon={faChevronRight} />
                                </NavLink>
                            </LinkTitle>
                            <br />
                        </Wrapper>
                        <br />
                    </Col>
                </Row>
            </Grid>
        </section>

    );
}

export default NoMatchPage;