import React from "react";
import { faGithubSquare, faInstagramSquare, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Icon = styled(FontAwesomeIcon)`
  color: #323741;
  margin: 0 .5rem;
  font-size: 1.95rem;
  :hover{
    animation: text-shadow-pop-bottom 0.6s both;
    color: darkcyan;
    transition: color .2s linear;
  }

  @keyframes text-shadow-pop-bottom {
    0% {
      text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555;
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
    }
  }
`;

export const Footer = styled.div`      
  text-align: center;
  margin-bottom: 1rem;
`;

export const Link = styled.a`      
    text-align: center;
    text-deceration: none;
`;

function NewFooter() {
  return (
    <Footer>
      <Grid>
        <Row middle="md">
          <Col xs={12}>
            <Link href="https://github.com/zay-asad">
              <Icon icon={faGithubSquare} />
            </Link>
            <Link href="https://www.instagram.com/traptechlabs/">
              <Icon icon={faInstagramSquare} />
            </Link>
            <Link href="https://www.facebook.com/traptechlabs/">
              <Icon icon={faFacebookSquare} />
            </Link>
          </Col>
          <Col xs={12}>
            <br />
              Copyright © 2021 - 2023 Traptech Labs
          </Col>
        </Row>
      </Grid>
    </Footer>
  );
}


export default NewFooter;