import React from "react";
import styled, { keyframes } from "styled-components";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import profile from "../assets/logo/vector/Artwork1.svg";

import { zoomInLeft, fadeInLeft } from "react-animations";

const fadeInAnimationTitle = keyframes`${zoomInLeft}`;
const fadeInAnimationLogo = keyframes`${fadeInLeft}`;

export const Title = styled.h1`      
    color: darkcyan;  
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 28px;
    animation: 1.5s ${fadeInAnimationTitle}
`;

export const SubTitle = styled.h2`      
    color: black; 
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
`;

export const Logo = styled.h1`      
    height: 230px;
    width: 230px;
    display: block;
    background-image: url(${profile});
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: center center;
    animation: 2s ${fadeInAnimationLogo}
`;

export const FormBox = styled.form`
    padding-top: 1.25rem;
`;

function NewHome() {
    return (
        <section id="page-wrap">
            <Grid>
                <Row>
                    <Col xs={12}>
                        <FormBox>
                            <Title>Traptech Labs</Title>
                        </FormBox>
                    </Col>
                    <Col xs={12}>
                        <br />
                        <SubTitle>Bespoke Web Design &</SubTitle>
                        <SubTitle>Development Services</SubTitle>
                        <br/>
                        <Logo />
                        <br/>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
}

export default NewHome;
